<template>
  <div class="orderinfo">
    <div class="header">
      <div class="left">
        <van-icon name="arrow-left" @click="gopath('back')" class="icon" />
      </div>
      <div class="title">订单信息</div>
    </div>
    <div class="content">
      <div class="orderid">订单编号：{{ data.orderNo }}</div>
      <div class="box">
        <div class="top">
          <img :src="data.orderCourseDetail.coverPath" class="top_left" />
          <div class="top_right">
            <div class="title">
              {{ data.orderCourseDetail.coursePackageName }}
            </div>
            <div class="price">¥{{ data.orderCourseDetail.price }}</div>
          </div>
        </div>
        <div class="center">
          <div class="item">
            <span class="text_t">有效期</span>
            <span class="text_p">{{
              data.orderCourseDetail.effectiveEtimeShow
            }}</span>
          </div>
          <div class="item">
            <span class="text_t">优惠券</span>
            <span class="text_p coupon"
              >-{{ data.couponPrice ? data.couponPrice : "0.00" }}</span
            >
          </div>
          <div class="item">
            <span class="text_t"
              >赠课
              <img
                src="@/assets/all_Icon/drawable-xxhdpi/icon_gift.png"
                class="icon_img"
            /></span>
            <span class="text_p">{{
              data.orderCourseDetail.giveCourseNames[0]
                ? data.orderCourseDetail.giveCourseNames[0]
                : "无"
            }}</span>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="item">
          <span class="text_t">总金额</span>
          <span class="text_p">{{
            data.totalPrice ? data.totalPrice : "0.00"
          }}</span>
        </div>
        <div class="item">
          <span class="text_t">总优惠</span>
          <span class="text_p">{{
            data.couponPrice ? data.couponPrice : "0.00"
          }}</span>
        </div>
        <div class="item">
          <span class="text_t">积分抵扣</span>
          <span class="text_p">{{
            data.pointDeductionPrice ? data.pointDeductionPrice : "0.00"
          }}</span>
        </div>
        <div class="item">
          <span class="text_t">应付金额</span>
          <span class="text_p">{{
            data.actuallyPrice ? data.actuallyPrice : "0.00"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getorderInfo } from "@/api/user.js";
import { filterTime } from "@/utils/utils.js";
export default {
  components: {},
  data() {
    return {
      data: {
        orderAddress: null,
        orderNo: "",
        orderCourseDetail: {
          coverPath: "",
          coursePackageName: "",
          price: "",
          effectiveEtime: null,
          effectiveEtimeShow: null,
          effectiveMonth: null,
          giveCourseNames: [],
          trackingNumber: null,
        },
        orderLiveDetail: null,
        totalPrice: "",
        couponPrice: "",
        pointDeductionPrice: "",
        actuallyPrice: "",
        payWay: "",
        payState: 0,
        shipState: 0,
        isTextbook: 0,
      },
    };
  },
  created() {
    this.getorderInfo();
  },
  methods: {
    getorderInfo() {
      let formData = new FormData();
      formData.append("orderId", this.$route.query.orderId);
      formData.append("orderType", this.$route.query.orderType);
      getorderInfo(formData).then((res) => {
        res.data.data.orderCourseDetail.effectiveEtimeShow = filterTime(
          res.data.data.orderCourseDetail.effectiveEtime
        );
        this.data = res.data.data;
        console.log(res);
      });
    },
    gopath() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.orderinfo {
  .header {
    padding: 10px 10px;
    display: flex;
    .left {
      width: 10%;
      .icon {
        font-size: 24px;
        font-weight: 600;
        color: #141414;
      }
    }
    .title {
      width: 85%;
      text-align: center;
      font-size: 17px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #141414;
    }
  }
  .content {
    font-size: 14px;
    background: #f8f8f8;
    padding: 14px 14px;
    min-height: 89vh;
    .box {
      padding: 14px;
      margin-top: 10px;
      background: #fff;
      .top {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding: 0 0 14px 0;
        .top_left {
          width: 36%;
        }
        .top_right {
          margin-left: 10px;
          width: 64%;
          .title {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 550;
            color: #333333;
            line-height: 20px;
            margin-bottom: 14px;
            overflow: hidden; //溢出隐藏
            text-overflow: ellipsis; //超出省略号显示
            white-space: nowrap; //文字不换行
          }
          .price {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: red;
            line-height: 18px;
          }
        }
      }
      .center {
        padding: 8px 0 0;
      }
    }
    .bottom {
      margin-top: 10px;
      background: #fff;
      padding: 14px;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .text_t {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141414;
        line-height: 20px;
        .icon_img {
          width: 16px;
          height: 16px;
        }
      }
      .text_p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141414;
        line-height: 20px;
      }
      .coupon {
        color: red;
      }
    }
  }
}
</style>


